import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2b734c86 = () => interopDefault(import('../pages/config.ts' /* webpackChunkName: "pages/config" */))
const _f75b8912 = () => interopDefault(import('../pages/daily_report.vue' /* webpackChunkName: "pages/daily_report" */))
const _07cd9281 = () => interopDefault(import('../pages/driver_schedule.vue' /* webpackChunkName: "pages/driver_schedule" */))
const _d7c6d240 = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _16488d48 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2d0901b0 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/config/",
    component: _2b734c86,
    pathToRegexpOptions: {"strict":true},
    name: "config"
  }, {
    path: "/daily_report/",
    component: _f75b8912,
    pathToRegexpOptions: {"strict":true},
    name: "daily_report"
  }, {
    path: "/driver_schedule/",
    component: _07cd9281,
    pathToRegexpOptions: {"strict":true},
    name: "driver_schedule"
  }, {
    path: "/forget_password/",
    component: _d7c6d240,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/login/",
    component: _16488d48,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reset_password/",
    component: _2d0901b0,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/",
    redirect: "/daily_report/",
    name: "index"
  }, {
    path: "*",
    redirect: "/daily_report/",
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
